<template>
  <div class="mt-75">
    <!-- <b-modal
      id="edit-modal"
      ref="edit-modal"
      no-close-on-backdrop
      ok-title="確認"
      centered
      header-bg-variant="primary"
      ok-only
    >
      <template #modal-title>
        <h4 class="m-0 model-header">
          編輯網域商
        </h4>
      </template> -->

    <div>
      <!-- 基本參數 -->
      <div class="mb-1">

        <div class="d-flex justify-content-end">
          <b-dropdown
            variant="link"
            no-caret
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              @click="copyArray"
            >
              <b-img
                :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>複製</span>
            </b-dropdown-item>

            <b-dropdown-item
              class="dropdown-item-area animate__animated animate__fadeIn"
              @click="pasteArray"
            >
              <b-img
                :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>匯入</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="domainExtraRule.length > 0"
              class="dropdown-item-area animate__animated animate__fadeIn"
              @click="cleanAllRules"
            >
              <b-img
                :src="require('@/assets/images/pages/ui/blue-line/trash-bin.svg')"
                class="dropdown-item-btn-image mr-50"
                rounded
              />
              <span>一鍵清空</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <draggable
          tag="ul"
          :list="domainExtraRule"
          class="list-group"
        >
          <li
            v-for="(element, index) in domainExtraRule"
            :key="`rules-${index}`"
            class="list-group-item"
          >
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <feather-icon
                    icon="AlignJustifyIcon"
                    size="20"
                    class="cursor-pointer mr-1"
                  />

                  <div>
                    <!-- 名稱 -->
                    <div
                      class="font-weight-bold cursor-pointer"
                      @click="selectedEditRow(element, index)"
                    >
                      <span
                        v-if="element.required"
                        class="required-key"
                      >
                        *
                      </span>{{ element.name }}
                      <small>( {{ element.key }} )</small>
                    </div>

                    <!-- 參數類型 -->
                    <div v-if="element.type">
                      <small class="text-muted d-flex">
                        類型 :
                        <div class="item-description ml-50">{{ resolveRuleMode(element.type).label }}</div>
                      </small>
                    </div>

                    <!-- 預設 -->
                    <div v-if="element.options.default">
                      <small class="text-muted d-flex">
                        預設 :
                        <div class="item-description ml-50">{{ element.options.default }}</div>
                      </small>
                    </div>

                    <!-- 參數範圍（字） -->
                    <div v-if="element.options.min || element.options.max">
                      <small class="text-muted d-flex">
                        參數範圍 :
                        <div class="item-description ml-50">{{ element.options.min ? element.options.min : 'null' }} ~ {{ element.options.max ? element.options.max : 'null' }}</div>
                      </small>
                    </div>

                    <!-- 正規表示式 -->
                    <div v-if="element.options.rule">
                      <small class="text-muted d-flex">
                        正規表示式 :
                        <div class="item-description ml-50">{{ element.options.rule }}</div>
                      </small>
                    </div>

                    <!-- 時間格式 -->
                    <div v-if="element.options.format">
                      <small class="text-muted d-flex">
                        時間格式 :
                        <div
                          v-if="element.options.format !== '自訂時間'"
                          class="item-description ml-50"
                        >
                          {{ element.options.format }}
                        </div>

                        <div
                          v-else
                          class="item-description ml-50"
                        >
                          {{ element.options.input }}
                        </div>
                      </small>
                    </div>

                    <!-- 串接符號 -->
                    <div v-if="element.options.delimiter">
                      <small class="text-muted d-flex">
                        串接符號 :
                        <div class="item-description ml-50">{{ element.options.delimiter }}</div>
                      </small>
                    </div>

                  </div>
                </div>

                <div class="d-flex text-nowrap align-items-center">
                  <div
                    class="actions-link-btn mr-25"
                    @click="selectedEditRow(element, index)"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="編輯"
                      :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>

                  <div
                    class="actions-link-btn"
                    @click="removeItem(element, index)"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="移除"
                      :src="require('@/assets/images/pages/ui/blue-line/close.svg')"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </div>
              </div>

            </div>
          </li>
        </draggable>
      </div>

      <slot name="empty-content" />

      <!-- 新增參數 -->
      <div class="text-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="sm"
          @click="addNewItem"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>新增參數</span>
        </b-button>
      </div>

      <!-- 規則設定 -->
      <domain-rule
        ref="domainRuleEditModal"
        domain-rule-id="domain-extra-rule-edit-modal"
        @edit-rule="editDomainData"
      />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  BButton, VBTooltip, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

import { useSwalToast, useAlert } from '@/libs/mixins/index'
import DomainRule from '../../domain-agent/components/DomainRule.vue'
import { useDomainSetting, useDomainAgent } from '../../useDomain'

export default {
  components: {
    BButton,
    BImg,
    draggable,
    BDropdown,
    BDropdownItem,
    DomainRule,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  props: {
    domainRuleItem: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      selectedIndex: null,
    }
  },
  methods: {
    // (複製)陣列
    copyArray() {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const copyData = JSON.stringify(this.domainExtraRule)
      if (!copyData) return
      navigator.clipboard.writeText(copyData)
        .then(() => {
          this.useAlertToast(true, '規則設定已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (貼上)陣列
    pasteArray() {
      navigator.clipboard.readText()
        .then(res => {
          const pasteData = JSON.parse(res)
          if (typeof pasteData === 'object' && pasteData.length) {
            const resolveData = pasteData.map((item, index) => {
              const resolveItem = this.syncObject(this.blankSetting, item)
              if (!resolveItem.name) resolveItem.name = `參數${index + 1}`
              if (!resolveItem.key) resolveItem.key = `key_${index + 1}`
              if (Array.isArray(item.options) && item.options.length === 0) resolveItem.options = {}
              return resolveItem
            })

            this.domainExtraRule = JSON.parse(JSON.stringify(resolveData))
            this.$emit('change-rule-state', true, this.domainExtraRule)
          } else this.useAlertToast(false, '資料型態不符')
        })
        .catch(() => {
          this.useAlertToast(false, '資料型態不符')
        })
    },

    // (編輯)參數設定
    editDomainData(item) {
      this.domainExtraRule[this.selectedIndex].key = item.key
      this.domainExtraRule[this.selectedIndex].name = item.name
      this.domainExtraRule[this.selectedIndex].required = item.required
      this.domainExtraRule[this.selectedIndex].options = item.options
      this.domainExtraRule[this.selectedIndex].type = item.type
      this.$emit('change-rule-state', true, this.domainExtraRule)
    },

    // (觸發)編輯規則
    selectedEditRow(item, index) {
      if (this.isBusy) return
      this.selectedIndex = index
      setTimeout(() => { this.$refs.domainRuleEditModal.getData(item) }, 200)
    },

    // 新增
    addNewItem() {
      if (this.isBusy) return
      const resolveData = JSON.parse(JSON.stringify(this.blankSetting))
      const index = this.domainExtraRule.length + 1
      resolveData.name = `參數${index}`
      resolveData.key = `key_${index}`
      this.domainExtraRule.push(resolveData)
      this.$emit('change-rule-state', true, this.domainExtraRule)
    },

    // (移除)所有規則
    cleanAllRules() {
      if (this.isBusy) return
      this.$swal({
        title: '刪除所有規則',
        text: '你確定要一鍵清除所有規則設定嗎?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.domainExtraRule = []
          this.$emit('change-rule-state', true, this.domainExtraRule)
        }
      })
    },

    // 移除
    removeItem(item, index) {
      if (this.isBusy) return
      this.$swal({
        title: '刪除參數',
        text: `你確定要刪除參數 ${item.name} 嗎?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.domainExtraRule.splice(index, 1)
          this.$emit('change-rule-state', true, this.domainExtraRule)
        }
      })
    },

    // (回傳)規則設定
    callbackExtraRule() {
      return this.domainExtraRule || []
    },
  },
  setup(props) {
    const {
      syncObject,
      resolveRuleMode,
    } = useDomainSetting()

    const {
      initBusy,
      blankSetting,
    } = useDomainAgent()

    const domainExtraRule = ref([])

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const resetDomainData = () => {
      domainExtraRule.value = []
    }

    const getDomainExtraData = () => {
      const resolveData = props.domainRuleItem.map(item => {
        const resolveItem = syncObject(blankSetting, item)
        if (Array.isArray(item.options) && item.options.length === 0) resolveItem.options = {}
        return resolveItem
      })

      domainExtraRule.value = JSON.parse(JSON.stringify(resolveData))
    }

    getDomainExtraData()

    return {
      initBusy,
      blankSetting,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      domainExtraRule,

      resetDomainData,
      resolveRuleMode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
.required-key {
  color: red;
}
.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}
</style>

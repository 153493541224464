<template>
  <div>
    <b-table
      :items="priceArray"
      :fields="tableColumns"
      hover
      show-empty
      responsive
      striped
      class="mb-0 table-responsive"
    >
      <!-- 查無資料 -->
      <template #empty>
        <div class="my-5 text-center animate__animated animate__fadeIn table-responsive-empty">
          <b-img
            :src="$store.state.app.themeImages.notFoundImg"
            fluid
            width="300"
            alt="查無資料"
          />
        </div>
      </template>

      <!-- 年數 -->
      <template #cell(quantity)="data">
        <div class="table-col">
          <div>
            {{ data.item.quantity != 1 ? `${data.item.quantity}年` : '首年' }}
          </div>
        </div>
      </template>

      <!-- 價格 -->
      <template #cell(price)="data">
        <div class="table-col">
          <div class="p-25">
            $ {{ data.item.price ? parseFloat(data.item.price, 10).toLocaleString() : 0 }}
          </div>
        </div>
      </template>

      <!-- 價格 -->
      <template #cell(trans)="data">
        <div class="table-col">
          <div style="padding: 5px;">
            $ {{ exchangeRate ? Math.ceil(data.item.price * exchangeRate).toLocaleString() : '---' }}
          </div>
        </div>
      </template>

    </b-table>
  </div>
</template>

<script>
import {
  BTable, BImg, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    priceArray: {
      type: Array,
      required: true,
    },
    exchangeRate: {
      type: Number || null,
      default: null,
    },
    costCurrency: {
      type: String,
      default: () => 'USD',
    },
  },
  data() {
    return {
      tableColumns: [
        { label: '年數', key: 'quantity' },
        { label: `價格(${this.costCurrency})`, key: 'price' },
        { label: '匯率換算(TWD)', key: 'trans' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.dark-layout {
  .table-responsive {
    background-color: #344464;
  }
}
.table-responsive {
  background-color: #f3f2f7;
}
</style>

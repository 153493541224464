<template>
  <div>
    <div>
      <b-form
        ref="form"
        class="repeater-form"
        :style="{height: trHeight}"
        @submit.prevent="repeateAgain"
      >
        <b-row
          v-for="(item, index) in options"
          :id="index"
          :key="index"
          ref="row"
          class="d-flex align-items-center"
        >
          <b-col cols="10">
            <b-row>
              <!-- 欄位: 選項名稱 -->
              <b-col cols="7">
                <b-form-group
                  label="選項名稱"
                  label-for="label"
                >
                  <b-form-input
                    v-model="item.label"
                    placeholder="請輸入選項名稱"
                  />
                </b-form-group>
              </b-col>

              <!-- 欄位: 對應資料 -->
              <b-col cols="5">
                <b-form-group
                  label="實際參數值"
                  label-for="name"
                >
                  <b-form-input
                    v-model="item.value"
                    placeholder="請輸入選項"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- 動作: 移除 -->
          <b-col cols="2">
            <b-button
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div class="text-center">
      <b-button
        variant="outline-primary"
        size="sm"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>新增</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BForm, BFormGroup, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  mixins: [heightTransition],
  props: {
    dataOptions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      if (this.options.length > 0) {
        if (!this.options[this.options.length - 1].label || !this.options[this.options.length - 1].value) return
      }
      this.options.push({
        label: null,
        value: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    removeItem(index) {
      this.options.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.options.length * 75)
      })
    },

    getOptionsData() {
      return this.options
    },
  },
  setup(props) {
    const options = ref([])

    options.value = JSON.parse(JSON.stringify(props.dataOptions)).map(item => ({
      label: item.label,
      value: item.value,
    }))

    return {
      options,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
</style>

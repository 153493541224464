<template>
  <!-- 頁面: 網域價格設定(權限: 完成, 註解: 刪除, 優化: 無) -->
  <div v-if="$store.state['admin-domain'].domainPriceInfo">
    <h3 class="font-weight-bolder">
      目前網域: <span class="server-name">{{ adminDomainState.domainPriceInfo.suffix }}</span>
    </h3>

    <b-row class="mb-2 mb-sm-0">
      <b-col
        cols="12"
        xl="9"
        lg="9"
      >
        <b-overlay
          :show="$store.state['admin-domain'].isBusyLoading"
          variant="transparent"
          opacity="0.9"
          rounded="sm"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                lg="7"
                md="6"
                class="mb-1"
              >
                <!-- SSID -->
                <b-form-group
                  label="SSID"
                  label-for="ssid"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminDomainState.domainPriceInfo.ssid ? adminDomainState.domainPriceInfo.ssid : '---' }}
                  </div>
                </b-form-group>

                <!-- 分站 -->
                <b-form-group
                  label="分站"
                  label-for="branch"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    <span v-if="adminDomainState.domainPriceInfo.branch_info && adminDomainState.domainPriceInfo.branch_info.name">{{ adminDomainState.domainPriceInfo.branch_info.name }}</span>
                    <span v-else>{{ adminDomainState.domainPriceInfo.branch_info.branch }}</span>
                  </div>
                </b-form-group>

                <!-- 是否為免費網域 -->
                <b-form-group
                  label="是否為免費網域"
                  label-for="is_free"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminDomainState.domainPriceInfo.is_free ? '是' : '否' }}
                  </div>
                </b-form-group>

                <!-- 當前排序 -->
                <b-form-group
                  label="當前排序"
                  label-for="rank"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <b-input-group
                    v-if="editCol === 'rank'"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="rank"
                      v-model="domainData.rank"
                      type="number"
                      :placeholder="editColData ? `${editColData}` : '請輸入當前排序'"
                    />

                    <b-input-group-append is-text>
                      <div>
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                          width="18"
                          class="mr-75 cursor-pointer"
                          @click="confirmEditCol"
                        />

                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                          width="18"
                          class="cursor-pointer"
                          @click="cancelEditCol"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>

                  <div
                    v-else
                    class="edit-col-label"
                    @click="selectEditCol('rank')"
                  >
                    <span>
                      {{ domainData.rank ? domainData.rank : '---' }}
                    </span>

                    <span class="selection-btn-icon">
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        height="15"
                        width="15"
                        class="cursor-pointer"
                      />
                    </span>
                  </div>
                </b-form-group>

                <!-- 網域商 -->
                <b-form-group
                  label="網域商"
                  label-for="ssid"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div
                    class="table-col text-nowrap cursor-pointer edit-col-label"
                    @click="submitSelectDealer"
                  >
                    <div>
                      <span>{{ adminDomainState.domainPriceInfo.domain_name_agent_info.agent ? adminDomainState.domainPriceInfo.domain_name_agent_info.agent : '---' }}</span>
                      <span
                        v-if="adminDomainState.domainPriceInfo.domain_name_dealer_info.id"
                        v-b-tooltip.hover.focus.v-secondary
                        :title="adminDomainState.domainPriceInfo.domain_name_dealer_info.name"
                        class="ml-50"
                      >
                        <b-img
                          :src="$store.state.app.themeImages.infoImg"
                          height="22"
                          width="22"
                        />
                      </span>
                    </div>
                  </div>
                </b-form-group>

                <!-- 轉移費用 -->
                <b-form-group
                  label="轉移費用"
                  label-for="name"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <b-input-group
                    v-if="editCol === 'transfer'"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="transfer"
                      v-model="domainData.transfer"
                      type="number"
                      :placeholder="editColData ? editColData : '請輸入轉移費用'"
                    />

                    <b-input-group-append is-text>
                      <div>
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                          width="18"
                          class="mr-75 cursor-pointer"
                          @click="confirmEditCol"
                        />

                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                          width="18"
                          class="cursor-pointer"
                          @click="cancelEditCol"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>

                  <div
                    v-else
                    class="edit-col-label"
                    @click="selectEditCol('transfer')"
                  >
                    <span>
                      {{ domainData.transfer ? `$ ${parseInt(domainData.transfer).toLocaleString()}` : '---' }}
                    </span>

                    <span class="selection-btn-icon">
                      <b-img
                        :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                        height="15"
                        width="15"
                        class="cursor-pointer"
                      />
                    </span>
                  </div>
                </b-form-group>

                <!-- 上次更新 -->
                <b-form-group
                  label="上次更新"
                  label-for="updated_at"
                  label-cols="4"
                  label-cols-lg="5"
                  label-cols-xl="4"
                  class="edit-col-from-group"
                >
                  <div class="mx-50">
                    {{ adminDomainState.domainPriceInfo.updated_at ? moment(adminDomainState.domainPriceInfo.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="5"
                md="6"
              >
                <h4 class="mb-0">
                  目前統計
                </h4>
                <vue-apex-charts
                  type="radialBar"
                  height="220"
                  class="mt-2"
                  :options="goalOverviewRadialBar"
                  :series="[percentage]"
                />
                <b-row class="text-center mx-0">
                  <b-col
                    cols="6"
                    class="border-right d-flex align-items-between flex-column pt-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      目標
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ adminDomainState.domainPriceInfo.target ? parseInt(adminDomainState.domainPriceInfo.target).toLocaleString() : 0 }}
                    </h3>
                  </b-col>

                  <b-col
                    cols="6"
                    class="d-flex align-items-between flex-column pt-1"
                  >
                    <b-card-text class="text-muted mb-0">
                      目前擁有數量
                    </b-card-text>
                    <h3 class="font-weight-bolder mb-0">
                      {{ parseInt(adminDomainState.domainPriceInfo.total, 10).toLocaleString() }}
                    </h3>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        lg="3"
      >
        <div class="mb-1">
          <b-card
            no-body
            class="mb-1"
          >
            <b-card-body class="pb-50">
              <div class="d-flex justify-content-between flex-row w-100">
                <div class="px-50">
                  <b-img
                    src="/dashboard/admin/images/sideIcon/domain-dealer.svg"
                    height="36"
                    rounded
                  />
                </div>

                <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                  <div>
                    <h5 class="font-weight-bolder mr-25 mb-25">
                      經銷商帳號
                    </h5>

                    <div>
                      <!-- <span>{{ adminDomainState.domainPriceInfo.domain_name_agent_info.agent ? adminDomainState.domainPriceInfo.domain_name_agent_info.agent : '---' }}</span>
                      <span
                        v-if="adminDomainState.domainPriceInfo.domain_name_dealer_info.id"
                        v-b-tooltip.hover.focus.v-secondary
                        :title="adminDomainState.domainPriceInfo.domain_name_dealer_info.name"
                      >
                        <b-img
                          :src="$store.state.app.themeImages.infoImg"
                          height="22"
                          width="22"
                        />
                      </span> -->
                      <span
                        v-if="adminDomainState.domainPriceInfo.domain_name_dealer_info.id"
                      >
                        {{ adminDomainState.domainPriceInfo.domain_name_dealer_info.name }}
                      </span>
                    </div>
                  </div>

                  <div>
                    <b-img
                      v-if="domainData.cost_state"
                      v-b-tooltip.hover.focus.v-secondary
                      title="價格調整"
                      class="alert-icon"
                      :src="require('@/assets/images/commen/warning-yellow.svg')"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <hr>

            <b-card-body class="pt-50">
              <b-overlay
                :show="isLoadingBusy"
                variant="transparent"
                opacity="0.9"
                rounded="sm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    xl="6"
                    lg="12"
                    md="3"
                    sm="6"
                  >
                    <div
                      class="d-flex align-items-center actions-link-btn ml-25 mb-25"
                      @click="submitSelectDealer"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/settings.svg"
                        class="actions-link-btn-image"
                      />
                      <span class="ml-25 text-primary">切換經銷商</span>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    lg="12"
                    md="3"
                    sm="6"
                  >
                    <div
                      class="d-flex align-items-center actions-link-btn ml-25 mb-25"
                      @click="onSubmitCostRefresh"
                    >
                      <b-img
                        src="/dashboard/admin/images/table/settings.svg"
                        class="actions-link-btn-image"
                      />
                      <span class="ml-25 text-primary">手動更新成本</span>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    lg="12"
                    md="3"
                    sm="6"
                  >
                    <div
                      class="d-flex align-items-center actions-link-btn ml-25 mb-25"
                      @click="onSubmitBalanceCheck"
                    >
                      <b-img
                        :src="require('@/assets/images/pages/order/point-wallet.svg')"
                        class="actions-link-btn-image"
                      />
                      <span class="ml-25 text-primary">餘額查詢</span>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    xl="6"
                    lg="12"
                    md="3"
                    sm="6"
                  >
                    <div
                      class="d-flex align-items-center actions-link-btn ml-25 mb-25"
                      @click="linkToDomainAgent"
                    >
                      <b-img
                        src="/dashboard/admin/images/sideIcon/domain-agent.svg"
                        class="actions-link-btn-image"
                      />
                      <span class="ml-25 text-primary">前往網域商</span>
                    </div>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-card-body>
          </b-card>

          <b-button
            variant="primary"
            block
            class="mb-1"
            @click="refreshDomainPriceData"
          >
            重新整理
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <!-- 上架狀態 -->
      <b-col
        lg="3"
        sm="6"
      >
        <b-card no-body>
          <b-card-body class="px-50">
            <div class="d-flex justify-content-between flex-row w-100">
              <div class="px-50">
                <b-img
                  :src="require('@/assets/images/pages/shop/domain/trade.svg')"
                  height="38"
                  rounded
                />
              </div>

              <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                <div>
                  <h5 class="font-weight-bolder mr-25 mb-25">
                    上架狀態
                  </h5>

                  <small>
                    啟用後可在前台展示
                  </small>
                </div>

                <div class="text-right">
                  <toggle-button
                    v-model="domainData.display"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="65"
                    :height="30"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: '關閉'}"
                    :sync="true"
                    :disabled="!checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                    @input="() => {
                      selectEditCol('display')
                      confirmEditCol()
                    }"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- 熱銷狀態 -->
      <b-col
        lg="3"
        sm="6"
      >
        <b-card no-body>
          <b-card-body class="px-50">
            <div class="d-flex justify-content-between flex-row w-100">
              <div class="px-50">
                <b-img
                  :src="$store.state.app.themeImages.fireImg"
                  height="38"
                  rounded
                />
              </div>

              <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                <div>
                  <h5 class="font-weight-bolder mr-25 mb-25">
                    熱銷狀態
                  </h5>

                  <small>
                    提升銷量、增加曝光
                  </small>
                </div>

                <div class="text-right">
                  <toggle-button
                    v-model="domainData.is_fire"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="65"
                    :height="30"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: '關閉'}"
                    :sync="true"
                    :disabled="!checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                    @input="() => {
                      selectEditCol('is_fire')
                      confirmEditCol()
                    }"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- 身分驗證 -->
      <b-col
        lg="3"
        sm="6"
      >
        <b-card no-body>
          <b-card-body class="px-50">
            <div class="d-flex justify-content-between flex-row w-100">
              <div class="px-50">
                <b-img
                  :src="require('@/assets/images/pages/customer/id-card.svg')"
                  height="38"
                  rounded
                />
              </div>

              <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                <div>
                  <h5 class="font-weight-bolder mr-25 mb-25">
                    身分驗證
                  </h5>

                  <small>
                    實名購買、保障交易安全
                  </small>
                </div>

                <div class="text-right">
                  <toggle-button
                    v-model="domainData.require_kyc"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="65"
                    :height="30"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: '關閉'}"
                    :sync="true"
                    :disabled="!checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                    @input="() => {
                      selectEditCol('require_kyc')
                      confirmEditCol()
                    }"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- 價格變動 -->
      <b-col
        lg="3"
        sm="6"
      >
        <b-card no-body>
          <b-card-body class="px-50">
            <div class="d-flex justify-content-between flex-row w-100">
              <div class="px-50">
                <b-img
                  src="/dashboard/admin/images/sideIcon/price.svg"
                  height="38"
                  rounded
                />
              </div>

              <div class="ml-25 d-flex justify-content-between align-items-center w-100">
                <div>
                  <h5 class="font-weight-bolder mr-25 mb-25">
                    價格調整
                  </h5>

                  <small>
                    檢查是否調整商品售價
                  </small>
                </div>

                <div class="text-right">
                  <toggle-button
                    v-model="domainData.cost_state"
                    :color="{checked: '#4683bb', unchecked: '#8f8f8f'}"
                    :switch-color="{checked: 'linear-gradient(40deg,#60bdff,#a8c9f8 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                    :width="65"
                    :height="30"
                    class="m-25 mb-50"
                    :labels="{checked: '開啟', unchecked: ''}"
                    :disabled="!domainData.cost_state || !checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                    :sync="true"
                    @input="() => {
                      selectEditCol('cost_state')
                      confirmEditCol()
                    }"
                  />
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <div>
      <b-row>
        <!-- 價格設定 -->
        <b-col
          cols="12"
          xl="8"
        >
          <b-card
            v-for="type in ['price_new', 'price_trans', 'price_continue']"
            :key="type"
          >
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h4 class="mb-0 font-weight-bolder">
                  {{ ui.priceType[type].name }}
                </h4>

                <b-img
                  v-if="isPriceUpdate[type]"
                  v-b-tooltip.hover.focus.v-secondary
                  title="尚未儲存"
                  class="alert-icon"
                  :src="require('@/assets/images/commen/warning-yellow.svg')"
                />
              </div>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitPriceRefresh(type)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                  />
                </div>

                <div
                  v-if="checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="儲存"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitPriceUpdate(type)"
                >
                  <b-img
                    src="/dashboard/admin/images/table/save.svg"
                    class="actions-link-btn-image"
                  />
                </div>
              </div>
            </div>

            <b-row class="match-height">
              <!-- 新註冊價格 -->
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col class="d-flex justify-content-start align-items-center">
                    <h5 class="m-0">
                      售價設定
                    </h5>
                  </b-col>

                  <b-col class="d-flex justify-content-end">
                    <b-dropdown
                      variant="link"
                      no-caret
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="copyArray(type)"
                      >
                        <!-- <feather-icon icon="CopyIcon" />
                        <span class="align-middle ml-50">複製</span> -->
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/copy.svg')"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>複製</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="pasteArray(type)"
                      >
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/paste.svg')"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>匯入</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="domainData[type].length > 0"
                        class="dropdown-item-area animate__animated animate__fadeIn"
                        @click="cleanArray(type)"
                      >
                        <b-img
                          :src="require('@/assets/images/pages/ui/blue-line/trash-bin.svg')"
                          class="dropdown-item-btn-image mr-50"
                          rounded
                        />
                        <span>一鍵清空</span>
                      </b-dropdown-item>
                    </b-dropdown>

                  </b-col>
                </b-row>

                <b-card
                  no-body
                  class="border price-card"
                >
                  <price-setting-table
                    :type="type"
                    :price-array="domainData[type]"
                    @sort-array="sortArray"
                    @remove-price="removePrice"
                  />

                  <div class="border-top add-btn">
                    <b-button
                      variant="flat-primary"
                      block
                      @click="addPrice(type)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">新增</span>
                    </b-button>
                  </div>

                </b-card>
              </b-col>

              <!-- 網域商價格 -->
              <b-col
                cols="12"
                md="6"
              >
                <div
                  style="height: 40px"
                  class="d-flex justify-content-start align-items-center"
                >
                  <h5 class="m-0">
                    網域商價格
                  </h5>
                </div>

                <b-card
                  no-body
                  class="border price-card"
                >
                  <price-setting-cost
                    :type="ui.priceType[type].cost"
                    :price-array="domainData[ui.priceType[type].cost]"
                    :exchange-rate="exchangeRate"
                    :cost-currency="adminDomainState.domainPriceInfo.cost_currency"
                  />

                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <!-- 註冊規則設定 -->
        <b-col
          cols="12"
          xl="4"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <h4 class="mb-0 font-weight-bolder">
                  註冊規則設定
                </h4>

                <b-img
                  v-if="isRuleUpdate"
                  v-b-tooltip.hover.focus.v-secondary
                  title="尚未儲存"
                  class="alert-icon"
                  :src="require('@/assets/images/commen/warning-yellow.svg')"
                />
              </div>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="重新整理"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitRuleRefresh"
                >
                  <b-img
                    src="/dashboard/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                  />
                </div>

                <div
                  v-if="checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')"
                  v-b-tooltip.hover.focus.v-secondary
                  title="儲存"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="submitRuleUpdate"
                >
                  <b-img
                    src="/dashboard/admin/images/table/save.svg"
                    class="actions-link-btn-image"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="!isRuleBusy"
              class="animate__animated animate__fadeIn"
            >
              <price-setting-rules
                ref="priceSettingRules"
                :domain-rule-item.sync="domainData.extra_rules"
                @change-rule-state="isRuleUpdateChange"
              >
                <template v-slot:empty-content>
                  <div
                    v-if="domainData.extra_rules.length === 0"
                    class="my-5 text-center animate__animated animate__fadeIn table-responsive-empty"
                  >
                    <b-img
                      :src="$store.state.app.themeImages.notFoundImg"
                      fluid
                      width="300"
                      alt="查無資料"
                    />
                  </div>
                </template>
              </price-setting-rules>
            </div>

            <div
              v-else
              class="loading-area"
            >
              <b-img
                :src="$store.state.app.themeImages.loadingImg"
                rounded
                height="60"
                width="60"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <admin-domain-dealer-modal
      ref="adminDomainDealerExplorerModal"
      domain-dealer-explorer-id="adminDomainDealerExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      :filter-agent="['Internet.bs', 'WebNIC']"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacDomainDealerExplorer"
    />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BButton, BFormGroup, BImg, BInputGroup, BFormInput, BInputGroupAppend, VBTooltip, BCardText,
  BDropdown, BDropdownItem, BCardBody, BOverlay,
} from 'bootstrap-vue'
import { ToggleButton } from 'vue-js-toggle-button'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import PriceSettingTable from './PriceSettingTable.vue'
import PriceSettingCost from './PriceSettingCost.vue'
import PriceSettingRules from './PriceSettingRules.vue'
import AdminDomainDealerModal from '@/layouts/components/Modal/admin-domain-dealer-modal/domainDealerModal.vue'
import { useDomainPrice, useDomainSetting } from '../../useDomain'
import { useSwalToast, useAlert, usePublicFunction } from '@/libs/mixins/index'
import useStoreModule from '../../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BCardBody,
    BCardText,
    BImg,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BOverlay,
    VueApexCharts,
    PriceSettingTable,
    PriceSettingRules,
    PriceSettingCost,
    AdminDomainDealerModal,
    ToggleButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast, usePublicFunction],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      editCol: null,
      editColData: null,
    }
  },
  computed: {
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
    percentage() {
      return 0
      // if (Math.round((this.adminDomainState.domainPriceInfo.total / this.adminDomainState.domainPriceInfo.target) * 100) > 100) return 100
      // return Math.round((this.adminDomainState.domainPriceInfo.total / this.adminDomainState.domainPriceInfo.target) * 100)
    },
  },
  watch: {
    'adminDomainState.domainPriceInfo': {
      handler() {
        this.initDomainInfo()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminDomainState.domainPriceInfo) {
      this.initDomainInfo()
    }
  },
  beforeDestroy() {
    this.$store.commit('admin-domain/UPDATE_DOMAIN_PRICE_INFO_STATE', null)
  },
  methods: {
    moment,
    // (初始化)網域資料
    initDomainInfo() {
      const resolveData = {
        ...this.syncObject(this.blankDomainData, this.adminDomainState.domainPriceInfo),
      }
      this.domainData = JSON.parse(JSON.stringify(resolveData))
    },

    // (儲存)價格
    submitPriceUpdate(type) {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')
      if (!auth) return
      const resolveData = {
        [type]: this.domainData[type],
        cost_state: false,
      }

      this.isBusy = true

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: resolveData,
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.isBusy = false
          this.isPriceUpdate[type] = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (儲存)規則
    submitRuleUpdate() {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')
      if (!auth) return
      const ruleData = this.$refs.priceSettingRules.callbackExtraRule()
      this.isBusy = true

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          extra_rules: ruleData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.isBusy = false
          this.isRuleUpdate = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (變動)規則
    isRuleUpdateChange(state, array) {
      this.isRuleUpdate = state
      this.domainData.extra_rules = array
    },
    // ----------------------------------------經銷商設定---------------------------------------------
    // (選擇)經銷商
    submitSelectDealer() {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')
      if (!auth) return
      this.$refs.adminDomainDealerExplorerModal.getData({
        ...this.adminDomainState.domainPriceInfo.domain_name_dealer_info,
        domain_name_agent_id: this.adminDomainState.domainPriceInfo.domain_name_agent_id,
      })
    },
    // (回傳)經銷商
    callbacDomainDealerExplorer(item, agent) {
      this.isBusy = true

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          domain_name_dealer_id: item.id,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          const resolveData = {
            ...this.$store.state['admin-domain'].domainPriceInfo,
            domain_name_dealer_info: { ...item },
            domain_name_agent_info: { ...agent },
            updated_at: new Date(),
          }
          store.commit('admin-domain/UPDATE_DOMAIN_PRICE_INFO_STATE', resolveData)
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (前往)網域商
    linkToDomainAgent() {
      const domainAgentDictionary = {
        WebNIC: 'https://www.webnic.cc/zh/home_tw/',
        'Internet.bs': 'https://internetbs.net/zh/index.html',
      }
      if (this.adminDomainState.domainPriceInfo.domain_name_agent_info.agent) {
        window.open(domainAgentDictionary[this.adminDomainState.domainPriceInfo.domain_name_agent_info.agent], '_blank')
      }
    },

    // (查詢)經銷商餘額
    onSubmitBalanceCheck() {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNameDealer.Balance')
      if (!auth) return
      if (this.isLoadingBusy) return
      const dealerInfo = this.adminDomainState.domainPriceInfo.domain_name_dealer_info
      this.isLoadingBusy = true
      this.getDomainDealerBalance({
        id: dealerInfo.id,
      }).then(response => {
        this.useSwalAlertCenter(true, '查詢成功', `經銷商 ${dealerInfo.name} 餘額為 ${response.data.data}`)
        this.isLoadingBusy = false
      })
        .catch(error => {
          this.useAlertToast(false, error.response.data.message)
          this.isLoadingBusy = false
        })
    },

    // (觸發)手動獲取最新價格
    onSubmitCostRefresh() {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainName.Cost')
      if (!auth) return
      const dealerInfo = this.adminDomainState.domainPriceInfo.domain_name_dealer_info
      const agentInfo = this.adminDomainState.domainPriceInfo.domain_name_agent_info
      const allowDealer = ['Internet.bs', 'WebNIC']
      if (!allowDealer.includes(agentInfo.agent)) return
      if (this.isLoadingBusy) return
      this.isLoadingBusy = true

      this.getDomainDealerCost({
        id: dealerInfo.id,
      }).then(() => {
        this.getDomainPriceResetData({
          id: this.adminDomainState.domainPriceInfo.id,
        }, callback => {
          this.domainData.cost_continue = callback.cost_continue
          this.domainData.cost_new = callback.cost_new
          this.domainData.cost_trans = callback.cost_trans
          this.domainData.cost_state = callback.cost_state
          this.useSwalAlertCenter(true, '更新成功', '已替換更新成本價格')
          this.isLoadingBusy = false
        }, () => {
          this.useSwalAlertCenter(true, '更新成功', '已成功更新成本價格')
          this.isLoadingBusy = false
        })
      })
        .catch(error => {
          this.useAlertToast(false, error.response.data.message)
          this.isLoadingBusy = false
        })
    },
    // ----------------------------------------欄位設定-----------------------------------------------
    // (選取)編輯欄位
    selectEditCol(type) {
      const auth = this.checkAuthAbility('domain', 'API.Admin.DomainNamePrice.Update')
      if (!auth) return
      this.editCol = type
      this.editColData = this.domainData[type]
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.domainData[this.editCol] = this.editColData
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      this.isBusy = true
      const resolveData = {
        [this.editCol]: this.domainData[this.editCol],
      }

      this.setDomainPriceUpdate({
        id: this.adminDomainState.domainPriceInfo.id,
        data: {
          ...resolveData,
        },
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.editCol = null
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
          this.editCol = null
        })
    },
    // ----------------------------------------價格設定-----------------------------------------------
    // (排序)陣列
    sortArray(type, index) {
      this.isPriceUpdate[type] = true
      const resolvArr = []
      this.domainData[type].forEach((item, n) => {
        if (n !== index) resolvArr.push(item)
      })
      const result = resolvArr.map(item => item.quantity).indexOf(this.domainData[type][index].quantity)
      if (result !== -1) {
        resolvArr[result].price = this.domainData[type][index].price
        this.domainData[type] = resolvArr
      }
      this.domainData[type].sort((a, b) => parseInt(a.quantity, 10) - parseInt(b.quantity, 10))
    },

    // (新增)價格
    addPrice(type) {
      const number = this.domainData[type].length > 0 ? parseInt(this.domainData[type][this.domainData[type].length - 1].quantity, 10) : 0
      let price = 1000
      if (this.domainData[type].length > 0) {
        price = parseInt(this.domainData[type][0].price, 10) * (number + 1)
      }
      const data = {
        quantity: number + 1,
        price,
      }
      this.domainData[type].push(data)
      this.isPriceUpdate[type] = true
    },

    // (移除)價格
    removePrice(type, index) {
      this.domainData[type].splice(index, 1)
      this.isPriceUpdate[type] = true
    },

    // (複製)陣列
    copyArray(type) {
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      const copyData = JSON.stringify(this.domainData[type])
      if (!copyData) return
      navigator.clipboard.writeText(copyData)
        .then(() => {
          this.useAlertToast(true, '價格已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (貼上)陣列
    pasteArray(type) {
      navigator.clipboard.readText()
        .then(res => {
          const pasteData = JSON.parse(res)
          if (typeof pasteData === 'object' && pasteData.length) {
            const tableData = pasteData.map((item, index) => {
              const data = {
                quantity: item.quantity ? item.quantity : (index + 1),
                price: item.price ? item.price : '1000',
              }
              return data
            })
            this.domainData[type] = tableData
            this.isPriceUpdate[type] = true
          } else this.useAlertToast(false, '資料型態不符')
        })
        .catch(() => {
          this.useAlertToast(false, '資料型態不符')
        })
    },

    // (清空)陣列
    cleanArray(type) {
      this.useSwalAlertWarning('清空價格', '你確定要清空所有價格嗎?')
        .then(result => {
          if (result.value) {
            this.domainData[type] = []
            this.isPriceUpdate[type] = true
          }
        })
    },

  },
  setup() {
    const DOMAIN_ADMIN_STORE_MODULE_NAME = 'admin-domain'

    if (!store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.registerModule(DOMAIN_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DOMAIN_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DOMAIN_ADMIN_STORE_MODULE_NAME)
    })

    const domainID = router.currentRoute.params.id

    const {
      isBusy,
      isRuleBusy,
      isLoadingBusy,
      domainData,
      goalOverviewRadialBar,
      getDomainPriceData,
      getDomainPriceResetData,
      setDomainPriceUpdate,
      isPriceUpdate,
      isRuleUpdate,
      exchangeRate,
    } = useDomainPrice()

    const {
      ui,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      syncObject,
      getExchangeRate,
      getDomainDealerCost,
      getDomainDealerBalance,
    } = useDomainSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankDomainData = {
      is_fire: false,
      display: false,
      require_kyc: false,
      cost_state: false,
      price_new: [],
      cost_new: [],
      price_trans: [],
      cost_trans: [],
      price_continue: [],
      cost_continue: [],
      transfer: 0,
      rank: null,
      domain_name_dealer_id: null,
      extra_rules: [],
    }

    // (獲取)匯率
    const getExchangeRateData = () => {
      const { domainPriceInfo } = store.state['admin-domain']
      const costCurrency = domainPriceInfo.cost_currency
      if (!costCurrency) return
      getExchangeRate({
        base: costCurrency || 'USD',
        target: 'TWD',
      })
        .then(response => {
          const { data } = response.data
          exchangeRate.value = data || null
        })
    }

    const submitPriceRefresh = type => {
      store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', true)
      getDomainPriceData({ id: domainID }, () => {
        // 紀錄當前陣列
        const remainDomain = {
          extra_rules: domainData.value.extra_rules,
          price_continue: domainData.value.price_continue,
          price_trans: domainData.value.price_trans,
          price_new: domainData.value.price_new,
        }

        domainData.value = JSON.parse(JSON.stringify(blankDomainData))
        // 紀錄舊格式
        const resolveData = {
          ...syncObject(blankDomainData, store.state['admin-domain'].domainPriceInfo),
        }

        const remainDomainData = {
          ...store.state['admin-domain'].domainPriceInfo,
          ...remainDomain,
        }

        domainData.value = {
          ...resolveData,
        }

        setTimeout(() => {
          domainData.value[type] = resolveData[type]
        }, 200)

        store.commit('admin-domain/UPDATE_DOMAIN_PRICE_INFO_STATE', remainDomainData)
        store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', false)
        isPriceUpdate.value[type] = false
      })
    }

    const submitRuleRefresh = () => {
      isRuleBusy.value = true
      store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', true)
      getDomainPriceData({ id: domainID }, () => {
        const remainDomain = {
          price_continue: domainData.value.price_continue,
          price_trans: domainData.value.price_trans,
          price_new: domainData.value.price_new,
        }
        domainData.value = JSON.parse(JSON.stringify(blankDomainData))
        const resolveData = {
          ...syncObject(blankDomainData, store.state['admin-domain'].domainPriceInfo),
        }
        const remainDomainData = {
          ...store.state['admin-domain'].domainPriceInfo,
          ...remainDomain,
        }
        domainData.value = {
          ...resolveData,
          extra_rules: resolveData.extra_rules,
        }
        store.commit('admin-domain/UPDATE_DOMAIN_PRICE_INFO_STATE', remainDomainData)
        store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', false)
        isRuleUpdate.value = false
        isRuleBusy.value = false
      })
    }

    const refreshDomainPriceData = () => {
      store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', true)
      isRuleBusy.value = true
      getDomainPriceData({ id: domainID }, () => {
        if (!exchangeRate.value) getExchangeRateData()
        isPriceUpdate.value = {
          price_new: false,
          price_trans: false,
          price_continue: false,
        }
        isRuleUpdate.value = false
        isRuleBusy.value = false
        store.commit('admin-domain/UPDATE_BUSY_LOADING_STATE', false)
      })
    }

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainData))
      isBusy.value = false
    }

    refreshDomainPriceData()
    resetDomainData()

    return {
      ui,
      useAlertToast,
      useHttpCodeAlert,
      kycRequiredOptions,
      displayOptions,
      isFireOptions,
      syncObject,
      domainData,
      goalOverviewRadialBar,
      blankDomainData,
      isPriceUpdate,
      isRuleUpdate,
      exchangeRate,
      isBusy,
      isRuleBusy,
      isLoadingBusy,
      resetDomainData,
      getExchangeRate,
      getDomainPriceResetData,
      refreshDomainPriceData,
      setDomainPriceUpdate,
      submitPriceRefresh,
      submitRuleRefresh,
      getDomainDealerCost,
      getDomainDealerBalance,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.edit-col-from-group {
  .vs__dropdown-toggle {
    border-radius: 5px 0 0 5px;
  }
}
</style>

<style lang="scss" scoped>
.dark-layout {
  .edit-col-from-group {
    .edit-col-label {
      &:hover {
        background: #aaaaaa20;
      }
    }
  }

  .add-btn {
    background-color: #505b7b;
  }
}

.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    width: calc(100% - 80px);
  }
}

.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}

.server-name {
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: red !important;
    text-decoration: underline
  }
}

.dropdown-item-area {
  transition: transform ease-out 200ms;
  .dropdown-item-btn-image {
    width: 14px;
    height: 14px;
  }

  &:hover {
    cursor: pointer;
    .dropdown-item-btn-image {
      transform: scale(1.2);
      animation: btn-breathe-todo-dropdown .8s linear infinite;
    }
  }
}

.price-card {
  display: flex;
  justify-content: space-between;
}

.add-btn {
  background-color: #fafafc;
  border-radius: 0  0 5px 5px;
}

.alert-icon {
  position: relative;
  top: -2px;
  width: 24px;
  transform: scale(1.05);
  animation: btn-breathe .8s linear infinite;
}

@keyframes btn-breathe-todo-dropdown {
  0%{ transform: scale(0.8); }
  50%{ transform: scale(1.2); }
  100%{ transform: scale(0.8); }
}

</style>
